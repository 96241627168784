import React from 'react'

import css from './Markers.module.scss'
import MarkerYellow from '../../../../assets/marker_yellow.svg'
import MarkerGreen from '../../../../assets/marker_green.svg'
import MarkerOrange from '../../../../assets/marker_orange.svg'
import MarkerRed from '../../../../assets/marker_red.svg'

const Markers = () => {
  return (
    <React.Fragment>
      <span className={css.MarkerYellow}>
        <img src={MarkerYellow} alt="marker-yellow" />
      </span>
      <span className={css.MarkerGreen}>
        <img src={MarkerGreen} alt="marker-green" />
      </span>
      <span className={css.MarkerOrange}>
        <img src={MarkerOrange} alt="marker-orange" />
      </span>
      <span className={css.MarkerRed}>
        <img src={MarkerRed} alt="marker-red" />
      </span>
    </React.Fragment>
  )
}

export default Markers
