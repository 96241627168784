import React from 'react'

import css from './Flora.module.scss'
import Bush from '../../../../assets/bush.svg'
import Tree from '../../../../assets/tree.svg'

const Flora = () => (
  <React.Fragment>
    <div className={css.Bushes}>
      {[...Array(10)].map((_, i) => (
        <span key={i}>
          <img src={Bush} alt="cloud" />
        </span>
      ))}
    </div>
    <div className={css.Trees}>
      {[...Array(10)].map((_, i) => (
        <span key={i}>
          <img src={Tree} alt="tree" />
        </span>
      ))}
    </div>
  </React.Fragment>
)

export default Flora
