import React, { useEffect, Fragment } from 'react'
import { Outlet, useLocation } from 'react-router-dom'

import Cars from './Cars/Cars'
import Clouds from './Clouds/Clouds'
import Flora from './Flora/Flora'
import Road from './Road/Road'
import Background from './Background/Background'

import css from './Layout.module.scss'
import Markers from './Markers/Markers'

const Layout = () => {
  let location = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  return (
    <Fragment>
      <main className={css.Main}>
        <Outlet />
      </main>
      <Road />
      <Cars />
      <Markers />
      <Flora />
      <Clouds />
      <Background />
    </Fragment>
  )
}

export default Layout
