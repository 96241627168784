import React from 'react'

import css from './Road.module.scss'

const Road = () => (
  <div className={css.RoadBkg}>
    <div className={css.Road}>
      <div className={css.Line}></div>
    </div>
  </div>
)

export default Road
