import React from 'react'

import css from './Clouds.module.scss'
import Cloud from '../../../../assets/cloud.svg'

const Clouds = () => {
  return (
    <div className={css.CloudsLr}>
      {[...Array(5)].map((_, i) => (
        <span key={i}>
          <img src={Cloud} alt="cloud" />
        </span>
      ))}
    </div>
  )
}

export default Clouds
